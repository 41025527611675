import { useEffect, useState } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import MessageContainer from "./container/messageContainer";
import MessageInput from "./input/messageInput";
import { v4 as uuidv4 } from "uuid";
import CategorySidebar from "./SalesSidebar";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";

const Chat = ({
  categories,
  handleItemClick,
  isLoading,
  userLang,
  setUserLang,
  setIsLoading,
  messages,
  setMessages,
  sessionId,
  setSessionId,
}) => {
  const [feedback, setFeedback] = useState(0);

  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("");

  useEffect(() => {
    if (location.state?.initialMessage) {
      setInitialMessage(location.state.initialMessage);
    }
  }, [location.state?.initialMessage]);
  
 
  
  const translateText = async (text, sourceLang, targetLang) => {
    try {
      const translateApiUrl = process.env.REACT_APP_TRANSLATE_API;
      console.log(
        `Translating text from ${sourceLang} to ${targetLang} via ${translateApiUrl}`
      );

      const response = await fetch(translateApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          sourceLang: sourceLang,
          targetLang: targetLang,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to translate text:", errorText);
        throw new Error("Failed to translate text");
      }

      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };
  const handleSendMessage = async (message, handleCategory) => {
    const senderMessage = {
      sender: "user",
      senderID: "test1",
      senderType: "admin",
      text: message,
    };
    setMessages((prevMessages) => [...prevMessages, senderMessage]);
    setIsLoading(true);

    try {
      // Step 1: Translate the user's message to English
      let translatedQuery = message;

      translatedQuery = await translateText(message, userLang, "English");
      console.log("Translated query to English:", translatedQuery);

      const combinedMessage = `${translatedQuery}`;
      const apiUrl = process.env.REACT_APP_CHAT_API;
      console.log(`Sending chat request to ${apiUrl}`);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: combinedMessage,
          sessionId: sessionId === "" ? null : sessionId,
          summary: "",
        }),
      });

      setIsLoading(false);
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Network response was not ok:", errorText);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Chat API data received:", data);

      const chatResponse = data.generated_text;

      const formattedResponse = chatResponse.startsWith('"')
        ? JSON.parse(chatResponse)
        : chatResponse;

      let translatedResponse = formattedResponse;
      if (userLang !== "English") {
        translatedResponse = await translateText(
          formattedResponse,
          "English",
          userLang
        );
        console.log(
          "Translated bot response to user language:",
          translatedResponse
        );
      }

      const botMessage = {
        sender: "bot",
        id: uuidv4(),
        text: translatedResponse,
        productDetails: data.productDetails,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      await saveChatSession(senderMessage, botMessage, feedback);
    } catch (error) {
      console.error("Error handling message:", error);
      setIsLoading(false);
    }
  };

  const saveChatSession = async (senderMessage, botMessage, feedback) => {
    try {
      const apiUrlSaveSession = process.env.REACT_APP_SAVE_CHAT_API;
      console.log(`Saving chat session to ${apiUrlSaveSession}`);

      const response = await fetch(apiUrlSaveSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error saving chat session:", errorText);
        throw new Error("Error saving chat session");
      }

      const data = await response.json();
      console.log("Chat session saved successfully:", data);
      setSessionId(data.id);
    } catch (error) {
      console.error("Error saving chat session:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error generating response" },
      ]);
    }
  };

  useEffect(() => {
    if (initialMessage ) {
  
      handleSendMessage(initialMessage);
      setInitialMessage("");
    }
  }, [initialMessage, messages]);
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: isMediumDown ? "16px 16px 0" : "16px",
        height: "100vh",
        overflowY: isMediumDown ? "auto" : "",
      }}
    >
      {isMediumDown && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            fetchpriority="high"
            width={100}
            height={44}
            src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
            class="attachment-full size-full wp-image-2014"
            alt=""
            srcset="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
            sizes="(max-width: 500px) 100vw, 500px"
          />
          <div
            style={{
              backgroundColor: "white",
              color: "red",
              padding: "2%",
              fontSize: "13px",
              fontWeight: "bold",
              borderRadius: "6px",
              width: "fit-content",
            }}
          >
            SALES AGENT
          </div>
        </div>
      )}
      {!isMediumDown && (
        <CategorySidebar
          categories={categories}
          handleItemClick={handleItemClick}
        />
      )}

      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isMediumDown
            ? "0px"
            : messages.length !== 0
            ? "220px"
            : "0",
          display: "flex",
          flexDirection: "column",
          marginTop: isMediumDown ? "2%" : "0%",
        }}
      >
        <MessageContainer
          messages={messages}
          sessionId={sessionId}
          feedback={feedback}
          onFeedbackChange={setFeedback}
          handleSend={handleSendMessage}
          loading={isLoading}
          categories={categories}
          handleItemClick={handleItemClick}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "sticky",
            bottom: "0",
            padding: isMediumDown ? "0" : 2,
          }}
        >
          <MessageInput
            handleSend={handleSendMessage}
            userLang={userLang}
            setUserLang={setUserLang}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Chat;
