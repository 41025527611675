import React from "react";
import { Grid } from "@mui/material";
import CardWrapper from "../atoms/cardWrapper";
import TypographyWrapper from "../atoms/TypographyWrapper";
import ProgressBar from "../atoms/progressBar";
const MetricCard = ({ title, value, progressValue = 70 }) => (
  <Grid item xs={12} sm={12}>
    <CardWrapper>
      <TypographyWrapper variant="h6">{title}</TypographyWrapper>
      <TypographyWrapper variant="subtitle2" fontWeight="bold">
        {value}
      </TypographyWrapper>
      <ProgressBar value={progressValue} />
    </CardWrapper>
  </Grid>
);

export default MetricCard;
