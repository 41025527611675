import React from "react";
import Navigator from "./Navigator";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const App = () => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      className="app"
      style={{
        background:
          "linear-gradient(177deg,hsla(218, 36%, 93%, 0.64) 29%,hsla(0, 0%, 100%, 0.64) 150%)",
        display: isMediumDown ? "flex" : "",
        flexDirection: isMediumDown ? "column-reverse" : "",
        height: isMediumDown ? "100vh" : "",
      }}
    >
      <Navigator />
    </div>
  );
};

export default App;
