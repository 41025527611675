import React from "react";
import { LinearProgress } from "@mui/material";

const ProgressBar = ({ value }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: 8,
        borderRadius: 5,
        backgroundColor: "#e0e0e0",
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundColor: value > 70 ? "green" : "orange",
        },
      }}
    />
  );
};

export default ProgressBar;
