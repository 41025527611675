import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AssistantIcon from "@mui/icons-material/Assistant";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

function UnibicNav() {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();

  const shouldShowTitleImage = location.pathname !== "/home";
  const getSelectedValue = () => {
    switch (location.pathname) {
      case "/home":
        return 0;
      case "/chat":
        return 1;
      case "/screens":
        return 2;
      case "/login":
        return 3;
      default:
        return -1;
    }
  };
  const [value, setValue] = useState(getSelectedValue());

  useEffect(() => {
    setValue(getSelectedValue());
  }, [location]);
  return (
    <>
      {isMediumDown ? (
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            if (newValue === 0) navigate("/home");
            if (newValue === 1) navigate("/chat");
            if (newValue === 2) navigate("/screens");
            if (newValue === 3) navigate("/login");
          }}
          sx={{
            position: isMediumDown ? "" : "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "inherit",
            zIndex: theme.zIndex.drawer + 1,
            "& .Mui-selected": {
              color: "#d52c2c",
            },
            "& .MuiBottomNavigationAction-root.Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                color: "#d52c2c",
              },
              "& .MuiSvgIcon-root": {
                color: "#d52c2c",
              },
            },
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Chat" icon={<AssistantIcon />} />
          <BottomNavigationAction
            label="Screens"
            icon={<TipsAndUpdatesIcon />}
          />
          <BottomNavigationAction label="Login" icon={<AccountCircleIcon />} />
        </BottomNavigation>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            height: "65px",
            boxShadow: "none",
            zIndex: theme.zIndex.drawer + 1,
            background: "inherit",
          }}
        >
          <Container
            sx={{ height: "100%", marginLeft: "0px", paddingLeft: "0px" }}
          >
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                height: "100%",
                gap: "2%",
              }}
            >
              {shouldShowTitleImage && (
                <Link
                  to="/home"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textDecoration: "none",
                    marginTop: "1%",
                  }}
                >
                  <img
                    fetchpriority="high"
                    width="80"
                    height="34.44"
                    src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
                    class="attachment-full size-full wp-image-2014"
                    alt=""
                    srcset="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
                    sizes="(max-width: 500px) 100vw, 500px"
                  />
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "red",
                      padding: "2%",
                      fontSize: "11px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                    }}
                  >
                    SALES AGENT
                  </div>
                </Link>
              )}
              <Link
                to="/home"
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bolder",
                  marginLeft: shouldShowTitleImage ? "" : "10%",
                }}
              >
                HOME
              </Link>
              <Link
                to="/chat"
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                CHAT
              </Link>
              <Link
                to="/screens"
                style={{
                  textDecoration: "none",
                  color: "hsl(207, 12%, 43%)",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                SCREENS
              </Link>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}

export default UnibicNav;
