import React, { useState } from 'react';
import './login.css';

const Login = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ userId: '', password: '' });

  const validateUserId = (value) => {
    const userIdPattern = /^[a-zA-Z]{4}\d{2}$/;
    return userIdPattern.test(value);
  };

  const handleUserIdChange = (e) => {
    const value = e.target.value;
    setUserId(value);
    setErrors({
      ...errors,
      userId: validateUserId(value) ? '' : 'UserId must contain 4 letters followed by 2 digits.',
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrors({
      ...errors,
      password: value.length < 6 ? 'Password must be at least 6 characters.' : '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors.userId && !errors.password) {
      alert('Login successful');
    }
  };

  return (
    <div className="container">
      <div className="left-section">
        <h2>Welcome back!</h2>
        <p>Login to your account using your email and password.</p>
      </div>
      <div className="right-section">
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label className="label">UserId</label>
            <input
              type="text"
              value={userId}
              onChange={handleUserIdChange}
              placeholder="Enter UserId"
              className="input"
            />
            {errors.userId && <span className="error">{errors.userId}</span>}
          </div>
          <div className="form-group">
            <label className="label">Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              className="input"
            />
            {errors.password && <span className="error">{errors.password}</span>}
          </div>
          <button type="submit" className="button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
