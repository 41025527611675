import { createTheme } from "@mui/material";

export const themeMui = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ffffff",
      contrastText:"hsl(207, 12%, 43%)"
    },
    secondary: {
      main: "#006c46",
      contrastText: "#FFFFFF",
    },
    background: {
      // default: "#212121",
      secondary: "#dbe1ff",
      alternative: "#eceffd",
    },
    error: {
      main: "#ba1a1a",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: [
      "Inter var",
      "Roboto",
      "system-ui",
      "Segoe UI",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          boxSizing: "content-box",
          margin: 0,
          padding: 0,
          scrollbarWidth: "7px",
          scrollbarHeight: "7px",
          "& ::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
          "& ::-webkit-scrollbar-track": {
            background: "#d2d2d2",
          },
          "& ::-webkit-scrollbar-thumb": {
            background: "#b0afaf",
            WebkitBorderRadius: "1ex",
            WebkitBoxShadow: "0px 1px 2px rgba(0, 0, 0, 0.75)",
          },
          "& ::-webkit-scrollbar-thumb:hover": {
            background: "#8d8c8c",
          },
        },
      },

      "@global": {
        "*, *::before, *::after": {
          boxSizing: "content-box",
          margin: 0,
          padding: 0,
          scrollbarWidth: "12px",
        },
        "*::-webkit-scrollbar": {
          height: "12px",
          width: "12px",
          background: "#000",
        },

        "*::-webkit-scrollbar-thumb": {
          background: "#393812",
          WebkitBorderRadius: "1ex",
          WebkitBoxShadow: "0px 1px 2px rgba(0, 0, 0, 0.75)",
        },
        body: {
          fontFamily:
            'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontSize: "1rem",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          borderRadius: "5px",
          "&::placeholder": {
            color: "black",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1440px",
        },
        maxWidthLg: {
          maxWidth: "1440px !important",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          maxWidth: "1440px",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
