import React from "react";
import { Grid, IconButton } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";

const NewOutletRadar = () => {
  const navigate = useNavigate();

  const {
    outletName,
    revenueForecast,
    introProducts,
    competitorStock,
    distance,
    firstScheduledVisit,
  } = dashboardData.newOutletRadar;

  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>

      <Grid container spacing={3}>
        {/* Header and Description */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">New Outlet Radar</TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              It helps you identify and{" "}
              <span style={{ backgroundColor: "yellow" }}>
                track new and fast-growing retail outlets
              </span>{" "}
              to optimize sales and distribution strategies.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Outlet Details */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Outlet Name
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {outletName}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Revenue Forecast
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {revenueForecast}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Intro Products
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {introProducts.join(", ")}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Competitor Stock
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {competitorStock}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Distance</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {distance}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              First Scheduled Visit
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {firstScheduledVisit}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default NewOutletRadar;
