import React from "react";
import { Grid, IconButton } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";

const HeroOfTheDayChallenge = () => {
  const navigate = useNavigate();
  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>

      <Grid container spacing={3}>
        {/* Challenge Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Hero of the Day Challenge
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.challenge}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Progress */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Progress</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Sold: {dashboardData.heroOfTheDayChallenge.progress.soldUnits}/
              {dashboardData.heroOfTheDayChallenge.progress.targetUnits}
              <span style={{ color: "red" }}>
                {" "}
                ({dashboardData.heroOfTheDayChallenge.progress.percentComplete})
              </span>
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Current Rank */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Current Rank
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="bold" color="red">
              #{dashboardData.heroOfTheDayChallenge.currentRank}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Close to Leader */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Close to Leader
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.closeToLeader}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Personal Achievement */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Personal Achievement
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              Sold{" "}
              {
                dashboardData.heroOfTheDayChallenge.personalAchievement
                  .unitsSold
              }{" "}
              units in{" "}
              {dashboardData.heroOfTheDayChallenge.personalAchievement.time}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Badge Earned */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Badge Earned
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.heroOfTheDayChallenge.badgeEarned}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Leaderboard */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Leaderboard
            </TypographyWrapper>
            {dashboardData.heroOfTheDayChallenge.leaderboard.map(
              (entry, index) => (
                <TypographyWrapper
                  key={index}
                  variant="body1"
                  fontWeight={entry.name === "You" ? "bold" : "normal"}
                  color={entry.name === "You" ? "red" : "normal"}
                >
                  #{entry.rank} - {entry.name} ({entry.unitsSold} units)
                </TypographyWrapper>
              )
            )}
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default HeroOfTheDayChallenge;
