import React from "react";
import { Container } from "@mui/material";

const ContainerWrapper = ({ children, maxWidth = "lg" }) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        height: "100vh",
        overflowY: "auto",
        paddingLeft: { xs: 0, md: "16px" },
        paddingRight: { xs: 0, md: "16px" },
        paddingTop: { md: "6%" },
      }}
    >
      {children}
    </Container>
  );
};

export default ContainerWrapper;
