import React from "react";
import { Container, Typography, Card, CardContent, Grid } from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  cursor: "pointer",
  transition: "color 0.2s ease",
  fontSize: "1.10rem",
  "&:hover": {
    color: "red",
  },
}));

const SmallArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "transform 0.1s ease",
  paddingLeft: "4px",
  paddingTop: "4px",
  fontSize: "1rem",
}));

const ScreenSections = () => {
  const themeItems2 = [
    {
      title: "Daily Sales Pulse",
      description: "Get real-time insights into daily sales trends.",
      link: "/today-sales",
    },
    {
      title: "Top 5 Outlet Opportunities",
      description: "Identify top outlets for daily growth ...",
      link: "/top-outlets",
    },
    {
      title: "New Outlet Radar",
      description: "Track recent outlet performance trends.",
      link: "/outlet-radar",
    },
    {
      title: "Hero of the Day Challenge",
      description: "Spotlight top-performing outlet for the day.",
      link: "/hero-of-the-day",
    },
    {
      title: "Product Push Priority",
      description: "Highlight items to focus on for sales boost.",
      link: "/product-push-priority",
    },
    {
      title: "Sales Obstacle Map",
      description: "Pinpoint challenges in today’s sales process.",
      link: "/sales-obstacle-map",
    },
    {
      title: "Instant Wins",
      description: "Quick actions yielding immediate sales...",
      link: "/instant-wins",
    },
    {
      title: "Next Best Action (NBA)",
      description: "Recommended actions to optimize sales",
      link: "/next-best-action",
    },
    {
      title: "Competitor Pulse",
      description: "Insights on competitor movements and ...",
      link: "/competitor-pulse",
    },
    {
      title: "Customer Feedback Scorecard",
      description: "Track customer feedback for quality...",
      link: "/feedback-scorecard",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        backgroundColor: "hsl(0deg 0% 86.7%))",
        paddingLeft: { xs: 0, md: "16px" },
        paddingRight: { xs: 0, md: "16px" },
        paddingBottom: { xs: 0, md: "20%" },
        height: { xs: "100vh", md: "auto" },
        overflowY: { xs: "auto", md: "unset" },
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          paddingTop: { xs: " 3%", md: "10.2%", sm: "9.0%", lg: "6.8%" },
          paddingBottom: { xs: "3%", md: "3.2%", sm: "2.0%", lg: "1.5%" },
          paddingLeft: { xs: "4%", md: "0%" },
          fontFamily: "sans-serif",
          fontSize: {
            xs: "1.5rem", // Font size for mobile screens
            sm: "1.9rem", // Font size for small screens (tablets)
            md: "2.6rem", // Font size for medium screens (desktop)
            lg: "2.9rem", // Font size for large screens
          },
        }}
      >
        Sales Overview
      </Typography>
      <Card
        sx={{
          padding: 3,
          boxShadow: 0,
          paddingTop: 5,
          borderRadius: { xs: 0, md: 5 },
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          fontWeight="bold"
          sx={{
            paddingBottom: { xs: "2.5%", md: "1%" },
            paddingTop: { xs: "2.5%", md: "1%" },
            fontFamily: "sans-serif",
          }}
        >
          Sales Growth & Strategy Insights{" "}
        </Typography>
        <Grid container spacing={2}>
          {themeItems2.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Link
                to={item.link}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card
                  sx={{
                    maxWidth: { xs: "100%", md: 500 },
                    margin: "auto",
                    boxShadow: 0,
                    borderRadius: 2,
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <TitleTypography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontFamily: "sans-serif",
                        fontSize: "0.9rem",
                      }}
                    >
                      {item.title} <SmallArrow />
                    </TitleTypography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          fontSize: "0.7rem",
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  );
};

export default ScreenSections;
