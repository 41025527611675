import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import CategorySidebar from "./SalesSidebar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ItemPage = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const { question, data, categories } = location.state || {};

  const [page, setPage] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(isMediumDown ? 6 : 10);
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  useEffect(() => {
    const handleResize = () => {
      const availableHeight = window.innerHeight * 0.8;
      const newItemsPerPage =
        availableHeight < 600
          ? 6
          : availableHeight < 640
          ? 7
          : availableHeight > 640 && availableHeight < 900
          ? 8
          : availableHeight > 900 && 10;
      setItemsPerPage(newItemsPerPage);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setPage(0);
  }, [location.pathname, location.state?.data]);

  const handleItemClick = async (item) => {
    const salesDataApi = `${process.env.REACT_APP_SALES_DATA_API}/${item.path}`;
    const response = await fetch(salesDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch data:", response.statusText);
      return;
    }

    const data = await response.json();
    navigate(`/item/${item.path}`, {
      state: {
        question: item.label,
        data: data,
        categories: categories,
      },
    });
  };

  return (
    <Container
      sx={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        background: "inherit",
        gap: "2%",
        margin: "0",
        height: "100vh",
        justifyContent: "flex-end",
        padding: "4%",
      }}
    >
      {isMediumDown ? (
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              position: "sticky",
              top: 0,
              height: "170px",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "15%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "rgb(213 216 219)",
                  height: "fit-content",
                  borderRadius: "6px",
                  color: "#3f3a3a",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: "14px",
                  padding: "1% 2% 1% 0",
                }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                <ChevronLeftIcon fontSize="small" />
                Home
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  fetchpriority="high"
                  width={100}
                  height={44}
                  src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
                  class="attachment-full size-full wp-image-2014"
                  alt=""
                  srcset="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
                <div
                  style={{
                    backgroundColor: "white",
                    color: "red",
                    padding: "2%",
                    fontSize: "13px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "6px",
                  }}
                >
                  SALES AGENT
                </div>
              </div>
            </div>

            <CategorySidebar
              categories={categories}
              handleItemClick={handleItemClick}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              marginLeft: "0%",
              marginTop: "3%",
            }}
          >
            <div
              style={{
                color: "hsl(207, 12%, 43%)",
                fontSize: "15px",
                marginTop: "3%",
                marginLeft: "2%",
                fontWeight: "bold",
                padding: "2px 5px",
                textAlign: "center",
              }}
            >
              {question}
            </div>
            <div
              style={{
                overflowX: "auto",
                marginLeft: "3%",
                marginTop: "1%",
                scrollbarWidth: "thin",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {displayedData.length > 0 &&
                      Object.keys(displayedData[0]).map((key, index) => (
                        <TableCell
                          key={key}
                          style={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "bold",
                            padding: "1.5% 2%",
                            backgroundColor: "lightblue",
                            position: index === 0 ? "sticky" : "static",
                            left: index === 0 ? 0 : "auto",
                            zIndex: index === 0 ? 1 : "auto",
                            backgroundClip: "padding-box",
                            whiteSpace: index === 0 ? "nowrap" : "normal",
                            minWidth: index === 0 ? "150px" : "100px",
                            maxWidth: "300px",
                          }}
                        >
                          {key}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedData.length > 0 ? (
                    displayedData.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {Object.values(row).map((value, colIndex) => (
                          <TableCell
                            key={colIndex}
                            style={{
                              color: "black",
                              fontSize: "14px",
                              padding: "1% 2%",
                              position: colIndex === 0 ? "sticky" : "static",
                              left: colIndex === 0 ? 0 : "auto",
                              zIndex: colIndex === 0 ? 1 : "auto",
                              // background: colIndex === 0 ? "white" : "inherit",
                              whiteSpace: "normal",
                              minWidth: colIndex === 0 ? "150px" : "200px",
                              maxWidth: colIndex === 0 ? "300px" : "500px",
                              backgroundColor:
                                rowIndex % 2 === 0 ? "white" : "#F8F8FC",
                            }}
                          >
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={Object.keys(displayedData[0] || {}).length}
                        style={{ color: "black" }}
                      >
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              <span>
                Page {page + 1} of {Math.ceil(data.length / itemsPerPage)}
              </span>
              <button
                onClick={() =>
                  setPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.floor(data.length / itemsPerPage) -
                        (data.length % itemsPerPage === 0 ? 1 : 0)
                    )
                  )
                }
                disabled={
                  (page + 1) * itemsPerPage >= data.length &&
                  data.length % itemsPerPage === 0
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ width: "20%" }}>
            <CategorySidebar
              categories={categories}
              handleItemClick={handleItemClick}
            />
          </div>
          <div style={{ width: "80%", paddingTop: "4%" }}>
            <div
              style={{
                color: "hsl(207, 12%, 43%)",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {question}
            </div>
            <Table style={{ marginTop: "1%" }}>
              <TableHead>
                <TableRow>
                  {displayedData.length > 0 &&
                    Object.keys(displayedData[0]).map((key) => (
                      <TableCell
                        key={key}
                        style={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "bold",
                          padding: "1.5%",
                          backgroundColor: "lightblue",
                        }}
                      >
                        {key}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.length > 0 ? (
                  displayedData.map((row, index) => (
                    <TableRow key={index}>
                      {Object.values(row).map((value, idx) => (
                        <TableCell
                          key={idx}
                          style={{
                            color: "black",
                            fontSize: "14px",
                            padding: "1%",
                          }}
                        >
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={Object.keys(displayedData[0] || {}).length}
                      style={{ color: "black" }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              <span>
                Page {page + 1} of {Math.ceil(data.length / itemsPerPage)}
              </span>
              <button
                onClick={() =>
                  setPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.floor(data.length / itemsPerPage) - 1
                    )
                  )
                }
                disabled={(page + 1) * itemsPerPage >= data.length}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ItemPage;
