import React from "react";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CardWrapper from "./atoms/cardWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import dashboardData from "./salesFieldExecutiveData";
import ContainerWrapper from "./atoms/containerWrapper";
import DataTable from "./atoms/tableWrapper";
const TopOutletOpportunities = () => {
  const navigate = useNavigate();
  const columns = [
    { label: "Outlet Name", field: "outletName" },
    { label: "Sales Potential", field: "salesPotential" },
    { label: "Last Purchase", field: "lastPurchase" },
    {
      label: "Top Products to Pitch",
      field: "topProductsToPitch",
      render: (row) =>
        row.topProductsToPitch
          ? row.topProductsToPitch.join(", ")
          : "No Products Available",
    },
    { label: "Estimated Revenue", field: "estimatedRevenue", align: "right" },
  ];

  // Row data
  const rows = dashboardData.topOutletOpportunities;

  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>
      <CardWrapper>
        <TypographyWrapper variant="h6">
          Top Outlet Opportunities
        </TypographyWrapper>
        <Typography variant="body2">
          Highlighting stores with high sales potential and recommended
          products.
        </Typography>

        <DataTable columns={columns} rows={rows} />
      </CardWrapper>
    </ContainerWrapper>
  );
};

export default TopOutletOpportunities;
