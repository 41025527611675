import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Chat from "./components/Chat";
import Screens from "./components/Screens";
import UnibicNav from "./components/navbar/unibicNavbar";
import ItemPage from "./components/ItemPage";
import Home from "./components/Home";
import SalesOverviewPage from "./components/screen/todaySales";
import TopOutletOpportunities from "./components/screen/topOutlets";
import NewOutletRadar from "./components/screen/outletRadar";
import HeroOfTheDayChallenge from "./components/screen/DayHero";
import ProductPushPriority from "./components/screen/productPushPriority";
import SalesObstacleMap from "./components/screen/salesObstacleMap";
import InstantWins from "./components/screen/instantWins";
import NextBestAction from "./components/screen/nextBestAction";
import CompetitorPulse from "./components/screen/competitorPulse";
import CustomerFeedbackScorecard from "./components/screen/customerFeedbackScoreCard";
import Login from "./pages/login";

const Navigator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userLang, setUserLang] = useState("English");
  const categories = [
    {
      title: "Top Selling Outlets",
      items: [
        {
          label: "What are my top selling outlets by the category size?",
          shortLabel: "By Category",
          mobileLabel: "Category",
          path: "top-selling-by-category-size",
        },
        {
          label: "What are my top selling outlets by the pack family?",
          mobileLabel: "Pack",
          shortLabel: "By Pack ",
          path: "top-selling-by-pack-family",
        },
        {
          label: "What are my top selling outlets by the brand family?",
          mobileLabel: "Brand",
          shortLabel: "By Brand ",
          path: "top-selling-by-brand-family",
        },
        {
          label: "What are my top 10 selling outlets?",
          mobileLabel: "Top 10",
          shortLabel: "Top 10 ",
          path: "top-10-selling-outlets",
        },
        {
          label: "What are my top 50 selling outlets?",
          mobileLabel: "Top 50",
          shortLabel: "Top 50 ",
          path: "top-50-selling-outlets",
        },
      ],
    },
    {
      title: "Non-Billed Outlets",
      items: [
        {
          label: "What are my non billed outlets in the last 15 days?",
          mobileLabel: "15 Days",
          shortLabel: "Last 15 Days",
          path: "non-billed-15-days",
        },
        {
          label: "What are my non billed outlets in the last 30 days?",
          mobileLabel: "30 Days",
          shortLabel: "Last 30 Days",
          path: "non-billed-30-days",
        },
        {
          label: "What are my non billed outlets in the last 45 days?",
          mobileLabel: "45 Days",
          shortLabel: "Last 45 Days",
          path: "non-billed-45-days",
        },
        {
          label: "What are my non billed outlets in the last 60 days?",
          mobileLabel: "60 Days",
          shortLabel: "Last 60 Days",
          path: "non-billed-60-days",
        },
      ],
    },
    {
      title: "Other Metrics",
      items: [
        {
          label: "Frequent sales of my top outlets",
          shortLabel: "Frequent Sales",
          mobileLabel: "Frequent",
          path: "frequent-sales-top-outlets",
        },
        {
          label: "Max sales outlets",
          shortLabel: "Max Sales",
          mobileLabel: "Max Sales",
          path: "max-sales-outlets",
        },
        {
          label: "Top contributor outlets",
          shortLabel: "Top Contributor ",
          mobileLabel: "Top Contributor",
          path: "top-contributor-outlets",
        },
      ],
    },
  ];
  const handleItemClick = async (item, navigate) => {
    const salesDataApi = `${process.env.REACT_APP_SALES_DATA_API}/${item.path}`;
    const response = await fetch(salesDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch data:", response.statusText);
      return;
    }

    const data = await response.json();
    navigate(`/item/${item.path}`, {
      state: {
        question: item.label,
        data: data,
        categories: categories,
      },
    });
  };
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const [sessionId, setSessionId] = useState(() => {
    return sessionStorage.getItem("chatSessionId") || "";
  });

  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
    sessionStorage.setItem("chatSessionId", sessionId);
  }, [messages, sessionId]);
  return (
    <Router>
      <UnibicNav />
      <Routes>
        <Route
          path="/home"
          element={
            <Home
              categories={categories}
              handleItemClick={handleItemClick}
              isLoading={isLoading}
              userLang={userLang}
              setUserLang={setUserLang}
            />
          }
        />
        <Route
          path="/chat"
          element={
            <Chat
              categories={categories}
              handleItemClick={handleItemClick}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              userLang={userLang}
              setUserLang={setUserLang}
              messages={messages}
              setMessages={setMessages}
              sessionId={sessionId}
              setSessionId={setSessionId}
            />
          }
        />
        <Route path="/screens" element={<Screens />} />
        <Route path="/login" element={<Login />} />
        <Route path="/item/:path" element={<ItemPage />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/today-sales" element={<SalesOverviewPage />} />
        <Route path="/top-outlets" element={<TopOutletOpportunities />} />
        <Route path="/outlet-radar" element={<NewOutletRadar />} />
        <Route path="/hero-of-the-day" element={<HeroOfTheDayChallenge />} />
        <Route path="/product-push-priority" element={<ProductPushPriority />} />
        <Route path="/sales-obstacle-map" element={<SalesObstacleMap />} />
        <Route path="/instant-wins" element={<InstantWins />} />
        <Route path="/next-best-action" element={<NextBestAction />} />
        <Route path="/competitor-pulse" element={<CompetitorPulse />} />
        <Route
          path="feedback-scorecard"
          element={<CustomerFeedbackScorecard />}
        />
      </Routes>
    </Router>
  );
};

export default Navigator;