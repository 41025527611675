const dashboardData = {
    dailySalesPulse: {
      todaysSales: { value: "₹150,000", target: "₹180,000", achieved: "83%" },
      topProductOfDay: { name: "Butter Cookies", unitsSold: 320 },
      totalRevenueWeekly: "₹900,000",
      leaderboard: { rank: 3, totalRanks: 15, nextMilestone: "₹170,000 to reach #2" },
      weeklyTargetCompletion: "70%",
      averageOrderValue: "₹3,000",
      conversionRate: "35%",
      returnRateToday: "2%"
    },
    topOutletOpportunities: [
      {
        outletName: "Mishra’s General Store",
        salesPotential: "High",
        lastPurchase: "3 Days Ago",
        topProductsToPitch: ["Almond Biscotti", "Oat Cookies"],
        estimatedRevenue: "₹25,000"
      },
      {
        outletName: "Sharma Mart",
        salesPotential: "Medium",
        productsToPitch: ["Sugar-Free Cookies"],
        estimatedRevenue: "₹18,000"
      }
    ],
    newOutletRadar: {
      outletName: "SuperMaya Market",
      revenueForecast: "₹15,000 Monthly",
      introProducts: ["Butter Cookies", "Biscotti Range"],
      competitorStock: "GoodDay Biscuits",
      distance: "1.2 km",
      firstScheduledVisit: "Tomorrow, 10 AM"
    },
    heroOfTheDayChallenge: {
      challenge: "Sell 50 Units of Choco Almond Cookies Today",
      progress: { soldUnits: 40, targetUnits: 50, percentComplete: "80%" },
      currentRank: 2,
      closeToLeader: "45 Units",
      personalAchievement: { unitsSold: 30, time: "1 Hour" },
      badgeEarned: "Cookie Champ",
      leaderboard: [
        { rank: 1, name: "Arjun", unitsSold: 45 },
        { rank: 2, name: "You", unitsSold: 40 }
      ]
    },
    productPushPriority: {
      topProduct: "Almond Biscotti",
      stockLevel: "High",
      currentSalesRankRegion: 2,
      unitsSoldToday: { value: 120, target: 200 },
      suggestedOutlets: ["Mishra’s General Store", "SuperMaya Market"],
      promotion: "10% Off for the Week"
    },
    salesObstacleMap: {
      lowStockOutlets: 4,
      topReturnIssues: "Packaging concerns on Chocolate Cookies",
      delayedDeliveries: { count: 3, averageDelay: "2 days" },
      customerComplaints: 8,
      majorCompetitorActivity: "Oreo promo at 3 outlets"
    },
    instantWins: {
      recentHighValueSale: { value: "₹30,000", outlet: "Sharma Mart" },
      topProductSold: "Choco Almond Cookies",
      customerFeedback: "Great taste and quality!",
      totalWinsToday: 4,
      newOutletAdded: "Delight Grocery"
    },
    nextBestAction: {
      suggestedOutlet: { name: "SuperMaya Market", distance: "1.2 km" },
      predictedSalesValue: "₹10,000",
      lastPurchaseDate: "10 Days Ago",
      orderHistory: { count: 5, averageValue: "₹8,000" }
    },
    competitorPulse: {
      competitorPromoObserved: "15% Off on GoodDay Cookies at Sharma Mart",
      topCompetitorProductsNearby: ["Oreo", "Sunfeast Digestive"],
      newCompetitorStockAtOutlets: { product: "GoodDay", location: "SuperMaya Market" },
      estimatedImpact: "Reduced sales on Digestive Line by 10%"
    },
    customerFeedbackScorecard: {
      satisfactionScore: "4.5/5",
      topPositiveFeedback: "Almond Biscotti is a hit!",
      topNegativeFeedback: "Packaging could be sturdier",
      repeatCustomerRate: "65%",
      resolvedIssues: { count: 10, total: 12 }
    }
  };
  export default dashboardData;
