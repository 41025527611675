import React from "react";
import { Grid, IconButton } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";

const SalesObstacleMap = () => {
  const navigate = useNavigate();

  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>
      <Grid container spacing={3}>
        {/* Sales Obstacle Map Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Sales Obstacle Map
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Identify key sales obstacles to plan mitigation strategies.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Low Stock Outlets */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Low Stock Outlets
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.lowStockOutlets} outlets
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Return Issues */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Return Issues
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.topReturnIssues}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Delayed Deliveries */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Delayed Deliveries
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.delayedDeliveries.count} delays
              (Avg. delay:{" "}
              {dashboardData.salesObstacleMap.delayedDeliveries.averageDelay})
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Customer Complaints */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Customer Complaints
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.customerComplaints} complaints
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Major Competitor Activity */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Major Competitor Activity
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.salesObstacleMap.majorCompetitorActivity}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default SalesObstacleMap;
