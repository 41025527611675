import React from "react";
import { Grid, IconButton } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";

const InstantWins = () => {
  const navigate = useNavigate();

  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>

      <Grid container spacing={3}>
        {/* Instant Wins Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">Instant Wins</TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Celebrate the immediate sales successes and customer feedback.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Recent High-Value Sale */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Recent High-Value Sale
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.recentHighValueSale.value} at{" "}
              {dashboardData.instantWins.recentHighValueSale.outlet}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Top Product Sold */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Top Product Sold
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.topProductSold}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Customer Feedback */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Customer Feedback
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              "{dashboardData.instantWins.customerFeedback}"
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Total Wins Today */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              Total Wins Today
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.totalWinsToday} wins
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* New Outlet Added */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">
              New Outlet Added
            </TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.instantWins.newOutletAdded}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default InstantWins;
