import React from "react";
import { Grid, IconButton } from "@mui/material";
import ContainerWrapper from "./atoms/containerWrapper";
import TypographyWrapper from "./atoms/TypographyWrapper";
import CardWrapper from "./atoms/cardWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dashboardData from "./salesFieldExecutiveData";

const NextBestAction = () => {
  const navigate = useNavigate();

  return (
    <ContainerWrapper>
      <IconButton onClick={() => navigate(-1)} aria-label="back">
        <ArrowBackIcon />
      </IconButton>

      <Grid container spacing={3}>
        {/* Next Best Action Header */}
        <Grid item xs={12}>
          <CardWrapper>
            <TypographyWrapper variant="h6">
              Next Best Action
            </TypographyWrapper>
            <TypographyWrapper variant="body2" fontWeight="normal">
              Here’s the suggested action to maximize sales.
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Suggested Outlet */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Suggested Outlet</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.suggestedOutlet.name}{" "}
              ({dashboardData.nextBestAction.suggestedOutlet.distance} away)
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Predicted Sales Value */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Predicted Sales Value</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.predictedSalesValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Last Purchase Date */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Last Purchase Date</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.lastPurchaseDate}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>

        {/* Order History */}
        <Grid item xs={12} md={6}>
          <CardWrapper>
            <TypographyWrapper variant="subtitle1">Order History</TypographyWrapper>
            <TypographyWrapper variant="body1" fontWeight="normal">
              {dashboardData.nextBestAction.orderHistory.count} orders with an average value of{" "}
              {dashboardData.nextBestAction.orderHistory.averageValue}
            </TypographyWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

export default NextBestAction;
